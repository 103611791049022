import React from 'react'
import styled from 'styled-components'
import create from 'zustand'
import Modal from '@/components/Modal/Modal'
import Close from '@/components/ui/button/Close'
import BookingForm from '@/components/shared/booking/form/BokingForm'
import { useBooking } from '@/api/hooks/useBooking'
import { translate } from '@/i18n'

type BookingDialogStore = {
  isOpen: boolean
  bookingId?: number
  nodeId?: number
  onClose: () => void
}

type BookingDialogProps = {
  bookingId?: number
  nodeId?: number
}

export const useBookingDialogStore = create<BookingDialogStore>((set) => ({
  id: undefined,
  isOpen: false,
  onClose: () => {
    set({
      bookingId: undefined,
      nodeId: undefined,
      isOpen: false
    })
  }
}))

export const bookingDialog = ({
  bookingId = undefined,
  nodeId = undefined
}: BookingDialogProps) => {
  useBookingDialogStore.setState({
    bookingId,
    nodeId,
    isOpen: true
  })
}

const BookingModal = () => {
  const bookingId = useBookingDialogStore((state) => state.bookingId)
  const isOpen = useBookingDialogStore((state) => state.isOpen)
  const onClose = useBookingDialogStore((state) => state.onClose)

  const { data, isLoading } = useBooking(bookingId)

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Header>
        <Title>{translate('booking')}</Title>
        <Close color="#000" onClick={onClose} />
      </Header>

      <Container>
        <BookingForm data={data} isLoading={isLoading} />
      </Container>
    </Modal>
  )
}

export default BookingModal

const Container = styled.div``

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
`
