import { useProjectStore } from '@/stores/projectStore'
import React from 'react'

const DateSelectorBar = () => {
  const fullMode = useProjectStore((state) => state.fullMode)

  if (!fullMode) return null

  return <div />

  // return <DateSelector />
}

export default DateSelectorBar
