import { addMinutes, format, startOfDay, startOfWeek } from 'date-fns'
import { api } from '../api'
import { ApiResponseType } from './types'
import { uniqArray } from '@/utils/helpers/array.helpers'

export enum BookingTypeEnum {
  Regular = 1,
  Recurent = 2,
  Constant = 3
}

export const bookingsAPI = {
  list({ workspaceId, projectId }: BookingListDto) {
    return api
      .get<BookingListResponse>(
        `project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=booking_list`
      )
      .then((res) => res.data)
  },
  add({
    bookingId,
    workspaceId,
    projectId,
    resourceId,
    userId,
    bookingType,
    dates,
    start,
    end
  }: AddBookingListDto) {
    const data = new FormData()
    const bookingData: any = {
      resource_type: 0,
      resource_id: resourceId,
      book_user: userId,
      booking_type: bookingType,
      id: bookingId
    }

    if (bookingType === BookingTypeEnum.Regular) {
      // Time format: 2022-01-01T00:00:00
      let intervals = dates.map((date) => [
        format(new Date(date.start), "yyyy-MM-dd'T'HH:mm:ss"),
        format(new Date(date.end), "yyyy-MM-dd'T'HH:mm:ss")
      ])

      if (start) {
        bookingData.start = format(new Date(start), "yyyy-MM-dd'T'HH:mm:ss")

        if (!intervals.length) {
          const today = new Date()
          const offset = today.getTimezoneOffset()
          intervals = [
            [
              format(
                addMinutes(startOfDay(today), offset),
                "yyyy-MM-dd'T'HH:mm:ss"
              ),
              format(
                addMinutes(startOfDay(today), 30 + offset),
                "yyyy-MM-dd'T'HH:mm:ss"
              )
            ]
          ]
        }
      }
      if (end) {
        bookingData.end = format(new Date(end), "yyyy-MM-dd'T'HH:mm:ss")
      }

      bookingData.intervals = intervals
    }

    if (bookingType === BookingTypeEnum.Recurent && start) {
      // @ts-ignore
      const gaps = getPeriods(dates)
      bookingData.gap = uniqArray(gaps)
      bookingData.start = format(new Date(start), "yyyy-MM-dd'T'HH:mm:ss")
      if (end) {
        bookingData.end = format(new Date(end), "yyyy-MM-dd'T'HH:mm:ss")
      }
    }

    if (bookingType === BookingTypeEnum.Constant && start) {
      // @ts-ignore
      // const gaps = getPeriods(dates)
      // bookingData.gap = gaps
      bookingData.start = format(new Date(start), "yyyy-MM-dd'T'HH:mm:ss")
      // bookingData.end = null
    }

    data.append('booking_request', JSON.stringify(bookingData))

    return api
      .post<BookingListResponse>(
        `project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=create_booking_extended`,
        data
      )
      .then((res) => res.data)
  },
  getInterval({
    workspaceId,
    projectId,
    nodeId,
    start,
    end,
    requestId,
    userId,
    exclude
  }: GetIntervalDto) {
    const data = new FormData()

    data.append('node_id', String(nodeId))
    data.append('start_date', String(start))
    data.append('end_date', String(end))
    data.append('request_id', String(requestId))
    data.append('for_user', String(userId))
    data.append('exclude_booking', String(exclude))

    return api
      .post<NodeBookingViewResponse>(
        `project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=node_booking_view_v2`,
        data
      )
      .then((res) => res.data)
  }
}

// @ts-ignore
const getPeriods: number[] = (dates: BookingDateItem[]) =>
  dates.reduce(
    (acc, val) => [
      ...acc,
      ...convertToPeriods(new Date(val.start), new Date(val.end))
    ],
    []
  )

export const convertToPeriods = (start: Date, end: Date, week?: Date) => {
  const monday = week || startOfWeek(start, { weekStartsOn: 1 })
  const startTime = start.getTime()
  const endTime = end.getTime()
  const startGap = Math.floor((startTime - monday.getTime()) / 1800000)
  const endGap = Math.ceil((endTime - monday.getTime()) / 1800000) - 1
  const gaps: number[] = []

  for (let i = startGap; i <= endGap; i++) {
    if (i > 355) {
      break
    }
    gaps.push(i)
  }

  return gaps
}

interface BookingListDto {
  workspaceId: number
  projectId: number
}

interface BookingDateItem {
  start: string
  end: string
}

interface AddBookingListDto extends BookingListDto {
  bookingId?: number | null
  resourceId: number
  userId?: number | null
  bookingType: number
  dates: BookingDateItem[]
  start?: Date
  end?: Date
}

interface GetIntervalDto extends BookingListDto {
  nodeId: number
  start: string
  end: string
  requestId?: number
  userId: number
  exclude?: number
}

export interface BookingListResponse extends ApiResponseType {
  items: BookingItem[]
  total: number
}

export interface BookingItem {
  id: string
  start: string
  end: string
  rec: string
  booking_type: string
  name: string
  parent_layer_id: string
  is_master: string
  user: string
  gap: string
  location: string[]
  bookable_id: string
}

export interface NodeBookingViewResponse extends ApiResponseType {
  node_booking_view_v2: {
    items: NodeBookingViewItem[]
    start: string
    end: string
    resource: number
    user: string
  }
}

export interface NodeBookingViewItem {
  reason: string
  point_id: number
  point_name: string
  point_type_name: string
  id: number
  rec: boolean
  start: string
  end: string
  user: number
  user_name: string
  gap: number[]
}
